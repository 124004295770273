import React from 'react';
import styles from './styles.module.scss';

const Faq = ({ items }) => {
  return (
    <div className={styles.base}>
      <ul className={styles.list}>
        {items.map(faq => (
          <li key={faq.contentful_id} className={styles.item}>
            <a href={faq.path}>{faq.question}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Faq;
