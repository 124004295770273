import React from 'react';
import { graphql } from 'gatsby';
import slugify from 'slugify';
import cx from 'classnames';

import DefaultLayout from '../layouts';
import { getComponent } from '../utils';
import Hero from '../components/Hero/Hero';
import Faq from '../components/Faq/Faq';
import SubscribeCTA from '../components/SubscribeCTA/SubscribeCTA';

const FaqPage = ({
  data: {
    contentfulFaqListingPage: { content, title, metaData, hero, callToAction },
  },
}) => {
  const faqs = content.filter(
    context => context.internal?.type === 'ContentfulFaq'
  );

  const items = faqs.map(faq => ({
    path: `/faq/${slugify(faq.question, { lower: true })}`,
    ...faq,
  }));

  return (
    <DefaultLayout pageTitle={title} metaData={metaData || undefined}>
      {hero && <Hero {...hero} />}
      <div
        className={cx('content', {
          'is-seamless': !!hero,
        })}
      >
        <div className="container container--compact">
          {!hero && <h1 className="h2 text-center">{title}</h1>}
          {content
            .filter(context => context.internal?.type !== 'ContentfulFaq')
            .map(context => {
              const Component = getComponent(
                context.internal.type.replace('Contentful', '')
              );

              return <Component key={context.id} {...context} />;
            })}
          <Faq items={items} />
        </div>
        {callToAction && <SubscribeCTA {...callToAction} />}
      </div>
    </DefaultLayout>
  );
};

export default FaqPage;

export const query = graphql`
  {
    contentfulFaqListingPage(contentful_id: { eq: "2HkGv9qbKeEF0e08BmE1Kg" }) {
      contentful_id
      title
      metaData {
        ... on ContentfulPageMeta {
          title
          metaDescription
          image {
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      hero {
        contentful_id
        heading
        image {
          id
          file {
            url
          }
        }
        ctaButton {
          buttonLink
          label
        }
      }
      content {
        ... on ContentfulFaq {
          question
          answer {
            raw
          }
          contentful_id
          internal {
            type
          }
        }
      }
      callToAction {
        contentful_id
        label
        buttonLink
        heading
      }
    }
  }
`;
